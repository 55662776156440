@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
}

.AppWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative; /* Needed for absolute positioning of the footer */
}

.App {
  flex: 1; /* This allows the content to expand and use available space */
}

.Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
